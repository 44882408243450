.clientdetailstablesection .MuiTableBody-root .MuiTableRow-root td,
.clientdetailstablesection .MuiTableBody-root .MuiTableRow-root td a {
  color: #000;
  font-size: 18px;
  font-weight: 500;
}

.clientdetailstablesection {
  margin-top: 30px !important;
}

.clientdetailstablesection .css-11xur9t-MuiPaper-root-MuiTableContainer-root {
  box-shadow: none !important;
}

.clientdetailstablesection .MuiTableHead-root th {
  color: #000;
  font-size: 20px;
  font-weight: 600;
}

.clientdetailstablesection .MuiTableBody-root .MuiTableRow-root td {
  color: #000;
  font-size: 18px;
  font-weight: 500;
}

.clientdetailstablesection .css-11xur9t-MuiPaper-root-MuiTableContainer-root {
    background-color: rgba(243, 241, 241, 30%) !important;
}

.clientdetailstablesection .css-15wwp11-MuiTableHead-root { 
    background-color: rgba(217, 217, 217, 30%);
}