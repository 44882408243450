.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.has-error {
  /* border-color: red !important; */
  border: 1px solid red !important;
}

.help-block {
  color: red;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.content-header {
  background-color: #382e2c !important;
  color: #d9d9d9;
  font-size: 14px;
  font-family: Inter;
  font-weight: 600;
  text-align: center;
  word-wrap: break-word;
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.header {
  height: 90px;
  background-color: #fff;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  display: flex;
  align-items: center;
  position: sticky;
  top: 48px; /* Adjust this value based on the height of the content-header */
  z-index: 980;
}

.sticky-wrapper {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  z-index: 1000;
}

.sticky-wrapper-header,
.sticky-wrapper-main-header {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0px;
  z-index: 1000;
}

/* .button-style { 
  background: #F16870; 
  border-radius: 20px; 
  justify-content: center; 
  align-items: center; 
  gap: 25px; 
  border-color: transparent;
  display: inline-flex;
  color: white; 
  font-size: 16px; 
  font-family: Inter; 
  font-weight: 600; 
} */
.login-header {
  height: 90px;
  background-color: white;
}
.content {
  height: 100% !important;
}

.button-section {
  font-family: Inter;
  padding: 8px 15px;
  font-weight: 600;
  font-size: 18px;
  color: #fff;
  background-color: #f16870;
  border-radius: 5px;
  border: none;
}

.header-section {
  padding: 47px 0px;
}

.welcome-text {
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.welcome-text h3 {
  font-weight: 600;
  font-size: 34px;
  color: #1e1e1e;
  margin-bottom: 0px;
}

.productdescription h3 {
  font-weight: 500;
  font-size: 16px !important;
  color: #1e1e1e;
  margin-bottom: 8px;
}

.productdescription p {
  font-weight: 400;
  font-size: 20px;
  color: #1e1e1e;
  margin-bottom: 0px;
}

.productdescription .card p {
  color: #fff;
}

.download-icon {
  margin-left: 10px;
}

.statussection {
  display: flex;
  align-items: center;
  justify-content: center;
}

.nextbuttonicon {
  margin-left: 10px;
  color: #f16870;
}

.nextbuttonicon svg {
  font-size: 24px;
}

.active-option {
  color: #f16870 !important;
}

.viewshoppingcartsection h5,
.viewshoppingcartsection h5 a {
  color: #f16870;
  font-size: 18px;
  text-decoration: none;
  margin-bottom: 0px;
}

.productdetailssection {
  border-bottom: 1px solid #d9d9d9;
}

/* .totalproductdetailssection .productdetailssection:last-child {
  border-bottom: none;
} */

.quantityinputsection {
  width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: none;
  outline: none;
  margin: 0 10px;
}

.cartproductquantity .quantityinputsection {
  width: 30px;
}

.productdescription .card {
  background-color: #f16870;
  color: #fff;
  height: 250px;
  display: flex;
  justify-content: flex-end;
}

.testimonialsection {
  background-color: #ffdac1;
}

.testimonialsection .card-columns .card {
  background-color: #ffffff;
  color: #382e2c;
  /* height: 250px; */
  display: flex;
  justify-content: flex-end;
}

.testimonialsection .card p,
.testimonialsection .card h3 {
  color: #382e2c;
}

.testimonialsection .card h3 {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 16px;
}

.testimonialsection .card p {
  font-size: 14px;
  font-weight: 400;
}

.productssection .delivered {
  color: #f16870;
  font-weight: 600;
}

.productssection .cancelled {
  color: #ff0202;
  font-weight: 600;
}

.productssection .inprogress {
  color: #005e66;
  font-weight: 600;
  text-decoration: underline;
}

.upload-save-btn {
  padding: 7px 40px !important;
  border-radius: 20px !important;
  background-color: #f16870 !important;
  color: #fff !important;
  font-size: 24px !important;
  font-weight: 600 !important;
}

.headericonsection {
  width: 30px;
}

/* .notificationsection {
  margin-right: 30px;
} */

.inputseachsection input {
  background-color: rgba(217, 217, 217, 40%);
  color: #382e2c;
  font-size: 20px;
  font-weight: 400;
  padding: 7px 15px;
  border-radius: 20px;
}

.sidebarmenusections {
  display: flex;
  align-items: center;
  padding: 10px 0;
  cursor: pointer;
  transition: color 0.3s ease;
}

.sidebarmenusections h3 {
  margin-left: 10px;
  transition: color 0.3s ease;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 0px;
}

.sidebarmenusections:hover svg path,
.sidebarmenusections.active svg path {
  fill: #f16870;
}

.sidebarmenusections:hover h3,
.sidebarmenusections.active h3 {
  color: #f16870;
}

.sidebar {
  padding: 55px 30px;
  background-color: #fff;
  position: fixed;
  top: 90px;
  left: 0;
  z-index: 980;
  width: 260px;
  height: 100%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.sticky-wrapper-main-header .header {
  box-shadow: none;
}

.headerandsidebar {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  position: sticky;
  top: 0;
  z-index: 980;
}

.main-container {
  margin-left: 260px;
  margin-top: 60px;
  margin-bottom: 80px;
}

.main-container .row {
  margin: 0 80px;
  /* margin-bottom: 90px; */
}

.nav-button-section {
  font-family: Inter !important;
  padding: 8px 30px !important;
  font-weight: 600 !important;
  font-size: 18px !important;
  color: #fff !important;
  background-color: #382e2c !important;
  border-radius: 10px !important;
  border: none !important;
  text-decoration: none !important;
  opacity: 1 !important;
  text-transform: uppercase !important;
}

.nav-button-section.active {
  color: #fff !important;
  background-color: #f16870 !important;
  opacity: 1 !important;
}

.css-1aquho2-MuiTabs-indicator {
  display: none;
}

.css-1wvnaxz-MuiTableCell-root.MuiTableCell-head,
.css-og52vs-MuiTableCell-root.MuiTableCell-head {
  background-color: #f9f9f9 !important;
  color: #f16870 !important;
  font-size: 16px;
  font-weight: 600;
}

.table > :not(caption) > * > * {
  background-color: #f9f9f9;
}

.table
  > :not(caption)
  > *
  > *
  .sellingpricetablesection
  .currentpricedatasection {
  background-color: #f9f9f9 !important;
}

.tableheader {
  color: #f79250;
  background-color: #382e2c;
  width: 120px;
  padding: 5px 0;
}

.sellingpricetablesection .tableheader,
.selectalltextsection {
  margin-left: 7px;
}

.tableheader h3 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0px;
  text-align: center;
}

.css-1enotpu-MuiTableRow-root:last-child td,
.css-1enotpu-MuiTableRow-root:last-child th {
  border: 1px solid #000 !important;
}

.costpricetablesection,
.sellingpricetablesection {
  margin-bottom: 30px;
}

.css-wjh20t-MuiPagination-ul li {
  border: 1px solid #d9d9d9;
}

.css-wjh20t-MuiPagination-ul {
  display: flex;
  align-items: stretch !important;
}

.css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  background-color: transparent !important;
  border-radius: 0px;
  margin: 0px;
  color: #f79250 !important;
}

@media (min-width: 1400px) {
  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1320px;
  }
}

@media (min-width: 1500px) {
  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1420px;
  }
}

@media (min-width: 1600px) {
  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1520px;
  }
}

@media (min-width: 1800px) {
  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1720px;
  }
}

@media (min-width: 1900px) {
  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1820px;
  }
}

@media (min-width: 2000px) {
  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1960px;
  }
}

.form-control:focus {
  box-shadow: none !important;
}

.sellingpricetablesection .currentpricedatasection {
  background-color: #fff !important;
  border: 1px solid #000 !important;
  border-radius: 10px !important;
  padding: 5px 40px;
}

.primaryheader,
.importantheader {
  font-size: 32px;
  font-weight: 600;
  color: #382e2c;
  margin-bottom: 30px;
}

.importantheader {
  text-transform: uppercase;
}

.selectalltextsection {
  font-size: 24px;
  font-weight: 600;
  color: #382e2c;
}

.teblecheckboxsection,
.sellingpricetable .css-1wvnaxz-MuiTableCell-root.MuiTableCell-head,
.sellingpricetable .sellingpricetablecheckboxsection,
.sellingpricetable .css-132a6sb-MuiTableCell-root {
  background-color: #fff !important;
  color: transparent !important;
  border: none !important;
  border-color: #fff !important;
}

.css-wop1k0-MuiDataGrid-footerContainer,
.MuiDataGrid-bottomContainer .MuiDataGrid-container--bottom {
  display: none !important;
}

.sellingpricetable .css-1wvnaxz-MuiTableCell-root {
  border: none !important;
}

.sellingpricetable
  .css-15wwp11-MuiTableHead-root
  .css-1wvnaxz-MuiTableCell-root,
.sellingpricetable
  .css-1enotpu-MuiTableRow-root
  .css-132a6sb-MuiTableCell-root {
  border: 1px solid transparent !important;
  border-right: 1px solid #000 !important;
}

.helpandreportsection h4 {
  font-size: 24px;
  color: #382e2c;
  font-weight: 600;
  margin-right: 40px;
  cursor: pointer;
}

.helpandreportsection h4:last-child {
  margin-right: 0px;
}

.deleteandediticonsection,
.deleteandediticon,
.editicon {
  cursor: pointer !important;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background-color: #ebebeb;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #6d6d6d;
}

.profilemenusection {
  position: absolute;
  background-color: #fff;
  right: -30px;
  top: 80px;
  padding: 24px;
  z-index: 9999 !important;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  width: max-content;
}

.profilemenusectionheader .profilemenuprofileprofilesection {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-image: url("./assets/icons/user.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.profilemenusectionheader .profilemenuprofiledetailssection {
  margin-left: 16px;
}

.profilemenusectionheader .profilemenuprofiledetailssection h6 {
  font-size: 12px;
  font-weight: 400;
  color: #382e2c;
  margin-bottom: 0px;
}

.profilemenusectionheader .profilemenuprofiledetailssection h3 {
  font-size: 16px;
  font-weight: 600;
  color: #382e2c;
  margin-bottom: 0px;
}

.profilemenusectionheader .profilemenuprofiledetailssection h5 {
  font-size: 14px;
  font-weight: 400;
  color: #382e2c;
  margin-bottom: 0px;
}

.profilelistoptionsection {
  cursor: pointer;
}

.profilelistoptionsection .profilelistoptions:hover h3 {
  color: #f16870;
}

.profilelistoptionsection .profilelistoptions:hover i {
  color: #f16870;
}

.profilelistoptions {
  margin-bottom: 8px;
}

.profilelistoptions:last-child {
  margin-bottom: 0px;
}

.profilelistoptions h3 {
  font-size: 16px;
  color: #382e2c;
  font-weight: 500;
  margin-bottom: 0px;
}

.profilesection {
  position: relative;
}

.profilemenusection::before {
  content: "";
  position: absolute;
  top: -10px;
  right: 10px;
  width: 20px;
  height: 20px;
  background: #fff;
  transform: rotate(45deg);
  z-index: -1;
}

.overlaydivsection {
  width: 100%;
  height: 15%;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.3) 30%,
    rgba(102, 102, 102, 0) 100%
  );
  z-index: 400;
  position: fixed;
  top: 0;
  left: 0;
}

.prepareinvoicespansection {
  margin-left: 10px;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.pricedivsection {
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.canvasjs-chart-canvas {
  border: 1px solid #656363;
  /* background-color: rgba(217, 217, 217, 0.4); */
  border-radius: 20px;
}

.productsalestablesection thead tr th {
  padding: 30px;
  color: #f16870;
}

.productsalestablesection tbody tr td {
  padding: 30px 50px;
}

.insightssection .allpricecardsection {
  margin-right: 45px;
  border-radius: 20px;
  padding: 26px 34px;
  /* width: 280px; */
}

.insightssection .allpricecardsection:last-child {
  margin-right: 0px;
}

.insightssection .costpricesection {
  color: #382e2c;
  background-color: #d9d9d9;
}

.insightssection .sellingpricesection {
  background-color: #dbffe1;
}

.insightssection .grosspricesection {
  background-color: #fff7d1;
}

.insightssection .sellingpricesection h3 {
  color: #007b5f;
}

.insightssection .grosspricesection h3 {
  color: #f79250;
}

.insightssection .sellingpricesection h5,
.insightssection .grosspricesection h5 {
  color: #656363;
}

.insightssection
  .analyticsyeardropdownsection
  .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  top: 50%;
  left: 30%;
  transform: translate(-50%, -50%);
}

.insightssection
  .analyticsyeardropdownsection
  .css-z3c6am-MuiFormControl-root-MuiTextField-root {
  width: 150px;
}

.productsalestablesection > :not(caption) > * > * {
  background-color: transparent;
}

.productsalestablerowsection {
  padding: 30px;
  border: 1px solid #656363;
  border-radius: 20px;
  background-color: rgba(217, 217, 217, 0.4);
}

.productsalestablerowsection h3 {
  padding: 30px;
  padding-top: 0px;
  border-bottom: 1px solid rgba(101, 99, 99, 0.4);
}

.customyearselectionsection .yearSelectionSection {
  /* padding: 10px 30px; */
  padding: 16.5px 30px;
  border-radius: 9px;
  background-color: #d9d9d9;
  border: none;
}

.invoiceanddcradiobuttonsections
  .css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked {
  color: #382e2c;
}

.invoiceanddcradiobuttonsections .css-vqmohf-MuiButtonBase-root-MuiRadio-root {
  color: rgba(56, 46, 44, 0.2);
}

.customdropdownsection {
  color: #382e2c;
  background-color: #d9d9d9;
  border-radius: 9px;
}

.customdropdownsection
  .css-sc8y68-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  border-radius: 9px;
}

.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  border-radius: 9px !important;
}

.css-6hp17o-MuiList-root-MuiMenu-list {
  padding: 0px !important;
}

.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root,
.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root:focus,
.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root:active,
.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root:focus-visible,
.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root:focus-within,
.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root:hover {
  border: none !important;
  outline: none !important;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root.Mui-focusVisible,
.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root.Mui-selected {
  background-color: rgba(0, 0, 0, 0.12) !important;
}

.monthlyquantitysoldheadersection,
.sales-summary-title {
  padding: 30px;
  padding-top: 0px;
  border-bottom: 1px solid rgba(101, 99, 99, 0.4);
}

.apexcharts-toolbar {
  display: none !important;
}

.barchartdivsection {
  background-color: rgba(217, 217, 217, 0.4);
  border: 1px solid #656363;
  border-radius: 20px;
  padding: 30px 40px;
}

.customdatepickersection .css-z3c6am-MuiFormControl-root-MuiTextField-root,
.customdatepickersection .customdatepickerdivsection,
.customdatepickersection .css-jupps9-MuiInputBase-root-MuiOutlinedInput-root {
  width: 360px !important;
  background-color: #d9d9d9 !important;
  border-radius: 9px !important;
  border: none !important;
  outline: none !important;
  /* color: #fff !important; */
}

.customdatepickersection .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: none;
}

.customdatepickersection .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  color: #fff;
}

.customdatepickersection .css-i4bv87-MuiSvgIcon-root {
  fill: #f16870;
}

.customdatepickersection .css-1b1fjlj-MuiFormControl-root-MuiTextField-root {
  width: 100% !important;
}

.customdatepickersection .css-1umw9bq-MuiSvgIcon-root {
  fill: #f16870;
}

.sales-summary-container {
  margin: 20px 0;
  background-color: rgba(217, 217, 217, 0.4);
  border: 1px solid #656363;
  border-radius: 20px;
  padding: 30px 40px;
}

.sales-summary-chart {
  margin-top: 20px;
}

.operationaldivsection {
  background-color: rgba(217, 217, 217, 0.13);
  border-radius: 20px;
  padding: 40px 30px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.operationallabelsection {
  margin-bottom: 12px;
  font-size: 20px;
  font-weight: 500;
}

.operationalSectioninputsection {
  margin-bottom: 44px;
}

.css-1blp12k-MuiInputBase-root-MuiOutlinedInput-root:hover
  .css-1ll44ll-MuiOutlinedInput-notchedOutline,
.css-1ll44ll-MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.css-1f6smbf:focus .css-16wblaj-MuiInputBase-input-MuiOutlinedInput-input,
.css-1f6smbf:focus-within
  .css-16wblaj-MuiInputBase-input-MuiOutlinedInput-input,
.css-1f6smbf:focus-visible
  .css-16wblaj-MuiInputBase-input-MuiOutlinedInput-input,
.css-16wblaj-MuiInputBase-input-MuiOutlinedInput-input {
  border-radius: 13px !important;
}

.fromDateSection .form-control {
  background-color: #d9d9d9 !important;
  padding: 16.5px 14px !important;
  border-radius: 9px !important;
}

.sidebar .disabled,
.sidebar .disabled:hover,
.sidebarmenusections.disabled:hover h3,
.sidebarmenusections.disabled.active h3,
.sidebarmenusections.disabled:hover svg path,
.sidebarmenusections.disabled.active svg path,
.disabled {
  color: #b0b0b0 !important;
  fill: #b0b0b0 !important;
}

.customyearselectionsection .yearSelectionSection {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.customyearselectionsection {
  position: relative;
}

.customyearselectionsection::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #382e2c;
  pointer-events: none;
}
.css-17e8nb1-MuiTableRow-root:last-child td,
.css-17e8nb1-MuiTableRow-root:last-child th {
  border: 1px solid #ccc; /* Set the border color and style as needed */
}

.orderandcartsection table th:last-child,
.orderandcartsection table tr:last-child th,
.orderandcartsection table tr:last-child td {
  border: 1px solid #000 !important;
}

.orderandcartsection .productstitleandsuggestionbuttonsection {
  margin-bottom: 16px;
}

.orderandcartsection .orderchecksection {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translate(-50%, -50%);
}

.orderandcartsection .orderandcarttablesection,
.clientscontainersection .clientdetailstablesection,
.productscontainerdivsection .productstablesection,
.dctablesection,
.invoicedivsection .invoicetabledivsection {
  margin-bottom: 40px;
}

/* .orderandcartsection .myorderscontainerdivsection,
.clientscontainersection .clientscontainerdivsection,
.productscontainerdivsection .clientscontainerdivsection,
.invoicedivsection .invoicecontainerdivsection {
  margin-bottom: 40px;
} */

.myorderscontainerdivsection .MuiSelect-select,
.clientscontainerdivsection .MuiSelect-select,
.productscontainerdivsection .MuiSelect-select,
.invoicedivsection .MuiSelect-select {
  width: 80px !important;
}

.myorderscontainerdivsection
  .MuiInputBase-root
  .css-1ll44ll-MuiOutlinedInput-notchedOutline,
.clientscontainerdivsection
  .MuiInputBase-root
  .css-1ll44ll-MuiOutlinedInput-notchedOutline,
.productscontainerdivsection
  .MuiInputBase-root
  .css-1ll44ll-MuiOutlinedInput-notchedOutline,
.invoicedivsection
  .MuiInputBase-root
  .css-1ll44ll-MuiOutlinedInput-notchedOutline {
  text-align: left;
  position: absolute;
  bottom: 0;
  right: 0;
  top: -5px;
  left: 0;
  margin: 0;
  padding: 0 8px;
  pointer-events: none;
  border-radius: inherit;
  border-style: solid;
  border-width: 1px;
  overflow: hidden;
  min-width: 0%;
  border: 1px solid rgba(0, 0, 0, 0.23) !important;
}

.orderandcartsection .MuiPagination-ul li,
.clientscontainersection .MuiPagination-ul li,
.productscontainerdivsection .MuiPagination-ul li,
.invoicedivsection .MuiPagination-ul li {
  border: 1px solid #d9d9d9 !important;
}

.orderandcartsection .MuiPagination-ul li .Mui-selected,
.clientscontainersection .MuiPagination-ul li .Mui-selected,
.productscontainerdivsection .MuiPagination-ul li .Mui-selected,
.invoicedivsection .MuiPagination-ul li .Mui-selected {
  background-color: transparent !important;
  border-radius: 0px !important;
  margin: 0px !important;
  color: #f79250 !important;
}

.orderandcartsection .totalproductdetailssection,
.clientscontainersection .totalproductdetailssection,
.productscontainersection .productstablesection,
.invoicetabledivsection {
  margin-bottom: 40px !important;
}

.orderandcartsection .orderdetailssection {
  margin-top: 40px;
}

.emptycartdivsection {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.emptycartcontentsection {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.emptycartdivsection .emptycartimagesection {
  width: 270px;
}

.emptycartdivsection h3 {
  font-size: 28px !important;
  margin-bottom: 4px !important;
  font-weight: 600 !important;
  text-align: center !important;
}

.emptycartdivsection h3 span {
  color: #f16870 !important;
}

.emptycartdivsection h5 {
  font-size: 16px !important;
  font-weight: 400 !important;
  text-align: center !important;
}

.emptycartdivsection button {
  font-family: Inter;
  padding: 8px 15px;
  font-weight: 600;
  font-size: 18px;
  color: #fff;
  background-color: #f16870;
  border-radius: 5px;
  border: none;
  margin-top: 30px;
}

.orderandcartsection .productdescription {
  margin-left: 30px;
}

.orderandcartsection .productimagedivsection {
  margin-left: 30px;
}

.orderandcartsection .productimagedivsection img {
  width: 300px;
  height: 250px;
  object-fit: fill;
}

.gobackbuttonsection {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}

.commoncontainerdivsection {
  margin-bottom: 80px;
}

/* INPUT CONTAINER  */
.reservation-box .input-container {
  display: flex;
  flex-direction: column;
  min-width: 217px;
  height: 60px;
  padding: 5px 10px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 0px 3px #000;
  cursor: pointer;
}

.reservation-box .input-container label {
  /* font-family: "Segoe UI"; */
  font-weight: 500;
  font-size: 11px;
  letter-spacing: 0.1em;
  color: #656363;
  text-transform: uppercase;
  margin-left: 2px;
}
.reservation-box .input-container input {
  font-weight: 500;
  font-size: 18px;
  text-transform: uppercase;
  border: none;
  outline: none;
  background: transparent;
  position: relative;
  top: 3px;
}

.reservation-box .button-container:hover .button {
  background: #000;
  color: #fff;
}

.invoice-header-section,
.myorders-header-section {
  margin-bottom: 40px;
}

/* RESERVATION BOX */
.reservation-box {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 30px;
  padding: 0px;
  margin-bottom: 40px;
  border-radius: 7px;
  background-color: rgba(255, 255, 255, 1);
}
/* .reservation-box .top {
  display: flex;
  flex-direction: table;
  gap: 50px;
  align-items: center;
} */

.reservation-box .info {
  color: #000;
  font-weight: 500;
  text-decoration: none;
  border-bottom: 2px solid transparent;
}
.reservation-box .info:hover {
  border-bottom: 2px solid #09f;
}

.MuiOutlinedInput-root {
  border: 1px solid #c4c4c4;
}

.customdatepickerrowsection {
  margin-top: 40px !important;
  margin-bottom: 30px !important;
}

.customdatepickerrowsection .reservation-box {
  margin-bottom: 0px;
  margin-top: 30px;
}

.customdatepickerrowsection .reservation-box .input-container {
  width: 275px;
  border: 1px solid #c4c4c4;
  box-shadow: none !important;
}

.css-1toxriw-MuiList-root-MuiMenu-list {
  padding: 0px !important;
}

.css-1blp12k-MuiInputBase-root-MuiOutlinedInput-root {
  border-radius: 13px !important;
}

.css-hvjq6j-MuiFormLabel-root-MuiInputLabel-root {
  background-color: #fff;
  padding: 0 5px;
}

.customdaterangesection {
  margin-bottom: 30px;
}

.invoiceanddcradiobuttondivsections {
  margin-bottom: 25px;
}

.invoiceanddccustomrangesection .reservation-box .input-container {
  width: 275px;
  border: 1px solid #c4c4c4;
  box-shadow: none !important;
}
