.login-image{
    background-image: url('assets/login_image.png'); 
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 88vh;
    width: 100%;
}

.content-column{
   display: flex !important;
   align-items: center !important;
   justify-content: center !important;
   text-align: center !important;
}

.login-input{
  font-size: 16px !important;
  padding: 11px 18px !important;
  padding-right: 50px !important;
  border: 1px solid #000 !important;
  border-radius: 12px !important;
  color: #000 !important;
  width: 450px !important;
}

.checkbox-login{
  display: flex !important;
  align-items: center !important;
}

.checbox-css{
  /* color: #F16870 !important; */
  padding-left: 0px !important;
  border-color: #000 !important;
}

.login-title{
  font-size: 30px !important;
  font-weight: 600 !important;
}

.login-subtitle{
  font-size: 20px !important;
  font-weight: 500 !important;
  margin-bottom: 32px !important;
}

.login-reachout-txt{
   color: #F16870 !important;
   cursor: pointer !important;
}