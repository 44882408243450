.orderstablesection .MuiTableHead-root th {
  color: #000;
  font-size: 20px;
  font-weight: 600;
}

.orderstablesection .MuiTableBody-root .MuiTableRow-root td {
  color: #000;
  font-size: 18px;
  font-weight: 500;
}

.orderstablesection .css-11xur9t-MuiPaper-root-MuiTableContainer-root {
  background-color: #f1f1f1 !important;
  padding: 8px 30px !important;
  box-shadow: none !important;
}

.css-177gid-MuiTableCell-root,
.css-1ex1afd-MuiTableCell-root,
.css-1ygcj2i-MuiTableCell-root,
.css-1azl6jz-MuiTableCell-root,
.css-1ndpvdd-MuiTableCell-root,
.css-1yhpg23-MuiTableCell-root {
  border-color: #656363 !important;
}

.prepareinvoicespansection {
  background-color: #382e2c;
  border-radius: 10px;
  color: #fff;
  padding: 7px 15px;
  text-transform: uppercase;
  cursor: pointer;
}

.orderupdatestatusdropdown option {
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #000 !important;
  border-radius: 0px !important;
  padding: 5px 0px !important;
}

.orderupdatestatusdropdown {
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #000 !important;
  width: 170px;
}
.orderupdatestatusdropdown.delivered {
  color: #077252 !important;
}

.orderupdatestatusdropdown.ontheway {
  color: #001c81 !important;
}

.orderupdatestatusdropdown.dispatched {
  color: #ac68f1 !important;
}

.orderupdatestatusdropdown.cancelled {
  color: #ff0000 !important;
}

.orderupdatestatusdropdown {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: url('data:image/svg+xml;charset=US-ASCII,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4 5"><path fill="none" stroke="black" stroke-width="1" d="M0 0l2 2 2-2"/></svg>')
    no-repeat right 30px center;
  background-color: white;
  background-size: 10px;
  border: 1px solid #ccc;
  font-size: 14px;
  font-weight: 600;
  color: #000;
  border-radius: 4px;
  cursor: pointer;
  padding: 8px 30px;
}

.orderupdatestatusdropdown:focus {
  outline: none;
  border-color: #007bff;
}
