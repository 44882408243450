.invoicetablesection .css-11xur9t-MuiPaper-root-MuiTableContainer-root, .billingtablesection .css-11xur9t-MuiPaper-root-MuiTableContainer-root {
  padding: 0px !important;
}

.invoicetablesection .MuiTableBody-root, .billingtablesection .MuiTableBody-root {
  padding: 0px 30px !important;
}

.invoicetablesection .css-1ygcj2i-MuiTableCell-root {
  background-color: #d9d9d9 !important;
  border: none !important;
}

.invoicetablesection .MuiTableBody-root, .billingtablesection .MuiTableBody-root {
  background-color: rgba(243, 241, 241, 40%) !important;
}

.invoicetablesection .MuiTableHead-root th, .billingtablesection .MuiTableHead-root th {
  color: #000;
  font-size: 24px;
  font-weight: 600;
  padding: 20px 40px;
}

.invoicetablesection .MuiTableBody-root .MuiTableRow-root td, .billingtablesection .MuiTableBody-root .MuiTableRow-root td {
  color: #000;
  font-size: 18px;
  font-weight: 500;
  padding: 20px 40px;
}

.invoicetablesection .css-11xur9t-MuiPaper-root-MuiTableContainer-root, .billingtablesection .css-11xur9t-MuiPaper-root-MuiTableContainer-root {
  background-color: transparent !important;
}

.invoicedetailstablesection, .billingdetailstablesection {
  margin-top: 50px !important;
}

.invoicedetailstablesection .MuiTableHead-root th, .billingdetailstablesection .MuiTableHead-root th {
  color: #000;
  font-size: 20px;
  font-weight: 600;
}

.invoicedetailstablesection .MuiTableBody-root .MuiTableRow-root td, .billingdetailstablesection .MuiTableBody-root .MuiTableRow-root td {
  color: #000;
  font-size: 18px;
  font-weight: 500;
}

.invoicedetailstablesection .css-11xur9t-MuiPaper-root-MuiTableContainer-root, .billingdetailstablesection .css-11xur9t-MuiPaper-root-MuiTableContainer-root {
  background-color: #f1f1f1 !important;
}

.invoicedetailstablesection .pricedivsection, .billingdetailstablesection .pricedivsection {
  background-color: #fff;
  border-radius: 10px;
  text-align: center;
  padding: 8px 0;
  font-size: 14px;
  font-weight: 600;
  color: #077252;
}

.grandtotalsection h3 {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 0px;
  color: #382e2c;
}

.invoicetablesection .css-11xur9t-MuiPaper-root-MuiTableContainer-root, .invoicedetailstablesection .css-11xur9t-MuiPaper-root-MuiTableContainer-root, .billingdetailstablesection .css-11xur9t-MuiPaper-root-MuiTableContainer-root, .billingtablesection .css-11xur9t-MuiPaper-root-MuiTableContainer-root {
  box-shadow: none !important;
}

.totalproductpricetrendsection .react-horizontal-scrolling-menu--scroll-container {
  -ms-overflow-style: none;  
  scrollbar-width: none; 
}

.totalproductpricetrendsection .productpricetrendsection {
  background-color: #F0F0F0;
  border-radius: 10px;
  padding: 20px 0;
  width: 250px;
  text-wrap: wrap;
  margin-right: 50px;
}

.totalproductpricetrendsection .productpricetrendsection h4 {
  margin-bottom: 15px;
}

.totalproductpricetrendsection {
  overflow: hidden;
  width: 100%;
  display: flex;
}

.totalproductpricetrendsection .scroll-container {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: hidden;
  white-space: nowrap;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none; 
}

.invoicestatussection {
  font-size: 18px !important;
  font-weight: 600 !important;
}