.modelImage {
    width: 80px;
  }

  .modelProductName h3 {
    font-weight: 500;
    font-size: 20px !important;
    color: #1e1e1e;
    margin-bottom: 8px;
}

.modelProductName h5 {
    font-size: 14px;
    font-weight: 400;
}
.modalproductquantity .modelinputsection {
    width: 54px !important;
}