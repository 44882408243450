.clientdetailssection .gobackicon {
  margin-right: 10px;
}

.clientdetailssection h6, .insightssection h6 {
  margin-bottom: 0px;
  font-size: 22px;
  font-weight: 600;
  color: #f16870;
}

.clientcredentialdetailstablesection {
  background-color: rgba(217, 217, 217, 13%);
  border-radius: 20px;
  padding: 40px 30px;
  margin-bottom: 80px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.clientcredentialdetailstablesection .clientcredentialinputsection {
  margin-bottom: 40px;
  font-size: 24px;
  font-weight: 500;
  color: #382e2c;
}

.clientcredentialdetailstablesection .clientcredentialinputsection:last-child {
  margin-bottom: 0px;
}

.clientprofilepicturesection {
  width: 100px;
  height: 100px;
  background: linear-gradient(to bottom, #ffa600, #f16870);
  border-radius: 100%;
}

.clientprofiledetailsection h3,
.clientaddressdetailsection h3,
.changepasswordsection h3 {
  margin-top: 10px;
  color: #382e2c;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 0px;
}

.clientprofiledetailsection .accountnumbersection {
  color: #f16870;
}

.clientprofiledetailsection h5,
.clientaddressdetailsection h5 {
  margin-top: 10px;
  color: #382e2c;
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 0px;
}

.clientaddressdetailsection {
  margin-top: 20px;
}

.editicon {
  margin-left: 10px;
}

.editdetailsbuttonsection, .editclientbuttonsection {
  margin-top: 30px;
}

.editclientbuttonsection .nav-button-section {
  text-transform: none !important;
  padding: 15px 30px !important;
  font-size: 24px !important;
  font-weight: 600 !important;
  border-radius: 20px !important;
}

.invoicesdashboardsection {
  border-radius: 20px;
  padding: 10px;
  width: 260px;
}

.invoicesdashboardsection h3 {
  font-size: 20px;
  font-weight: 600;
  color: #382e2c;
  padding-left: 15px;
}

.totalinvoicesection {
  background-color: #d9d9d9;
}

.paidinvoicessection {
  background-color: #dbffe1;
}

.pendinginvoicessection {
  background-color: #fff7d1;
}

.paidinvoicesandpendinginvoicessection h3 {
  color: #656363;
}

/* .invoiceandinvoicedatesection {
  text-align: left;
} */

.invoiceandinvoicedatesection h3 {
  font-size: 20px;
  font-weight: 600;
  color: #000;
}

.invoiceandinvoicedatesection h5 {
  font-size: 14px;
  font-weight: 400;
  color: #000;
}

.orderupdatestatusdropdown option {
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #000 !important;
  border-radius: 0px !important;
  padding: 5px 0px !important;
}

.orderupdatestatusdropdown {
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #000 !important;
  width: 170px;
}
.orderupdatestatusdropdown.delivered {
  color: #077252 !important;
}

.orderupdatestatusdropdown.ontheway {
  color: #001c81 !important;
}

.orderupdatestatusdropdown.dispatched {
  color: #ac68f1 !important;
}

.orderupdatestatusdropdown.cancelled {
  color: #ff0000 !important;
}

.clientinvoicedetailtablesection {
  margin-top: 30px;
}

.clientinvoicedetailtablesection .css-1ndpvdd-MuiTableCell-root {
  font-size: 20px;
  font-weight: 600;
  color: #000;
}

.clientinvoicedetailtablesection .css-1ndpvdd-MuiTableCell-root,
.clientinvoicedetailtablesection .invoiceclientamountsection {
  font-size: 20px;
  font-weight: 600;
  color: #000;
}

.upload-computer {
  text-decoration: underline;
}

.clientdetailssection
  .input-box-section
  .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root,
.clientdetailssection .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root {
  border-radius: 13px;
  /* border: 1px solid #000; */
}

.clientinvoicedetailsgobacksection h6 {
  margin-bottom: 0px;
  font-size: 22px;
  font-weight: 600;
  color: #f16870;
}

.billingfiltersection {
  background-color: #f1f1f1;
  border-radius: 20px;
  padding: 20px;
}

.billingfiltersection .inputsection, .billingfiltersection .dropdownsection, .billingfiltersection .dropdownsection:focus, .billingfiltersection .dropdownsection:active, .billingfiltersection .dropdownsection:focus-within, .billingfiltersection .dropdownsection:focus-visible {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #382E2C;
  border-radius: 0px;
  box-shadow: none;
}

.billingfiltersection h4, .billingfiltersection select{
    font-size: 18px;
    margin-bottom: 0px;
}

.newpasswordsection input, .currentpasswordsection input {
  border: 1px solid #000;
  border-radius: 13px;
}

.css-15wb8q1, .css-1f6smbf {
  border: 1px solid #000;
}